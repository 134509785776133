import RenderUtil from '@/utils/RenderUtil';
import { useModel } from '@umijs/max';
import { usePrevious } from 'ahooks';
import { CSSProperties, FC, useMemo } from 'react';
import { isMobile } from 'szfe-tools';

interface ValueDisplayProps {
  value: string | number;
  className?: string;
  style?: CSSProperties;
  len?: number; //格式化长度
  ratio?: boolean; //百分比
  isSubLen?: boolean; //是否裁剪长度
  propsColor?: string;
  fontSize?: number;
  // 字符前缀
  unit?: string;
  // 后缀单位
  suffix?: string;
  // 是否打开箭头
  isArrow?: boolean;
  //
  justify?:
    | 'justify-end'
    | 'justify-center'
    | 'justify-start'
    | 'justify-between';

  // 传了这个属性的话 ，就是根据日涨幅
  ratioValue?: any;
}

/**
 *  显示浮动价格 ，无背景颜色，绿涨红跌
 */
const ValueDisplay: FC<ValueDisplayProps> = ({
  value,
  style,
  className,
  len = 6,
  ratio,
  propsColor,
  isSubLen = true,
  unit,
  suffix,
  isArrow = false,
  justify = 'justify-center', // justify-end | justify-center | justify-start
  ratioValue,
  fontSize = 14,
}) => {
  const oldValue = usePrevious(value) ?? '0';
  const {isMobile} = useModel('system')
  const comStyle = {
    borderRadius: '5px',
    display: 'inline-block',
  };

  let color: any = {
    ...comStyle,
  }; // 默认颜色

  // 红涨绿跌设置
  const { upDownColorConfig } = useModel('system');

  const displayValue = value !== '0' ? value : oldValue;
  if (Number(value) > 0) {
    // 涨

    color = {
      ...comStyle,
      color: upDownColorConfig === 'upGreen' ? '#2db880' : '#ee4456',
    };
  } else {
    color = {
      //跌
      ...comStyle,
      color: upDownColorConfig === 'upGreen' ? '#ee4456' : '#2db880',
    };
  }

  if (ratioValue && ratioValue !== 0) {
    if (ratioValue >= 0) {
      color = {
        ...comStyle,
        color: upDownColorConfig === 'upGreen' ? '#2db880' : '#ee4456',
      };
    } else {
      color = {
        //跌
        ...comStyle,
        color: upDownColorConfig === 'upGreen' ? '#ee4456' : '#2db880',
      };
    }
  }

  const combinedStyle = {
    ...color,
    ...style,
    width: '100%',
  };

  // 判断value是否是大于0
  const showShow = useMemo(() => {
    if (Number(ratioValue) > 0) {
      return '+';
    } else {
      return '';
    }
  }, [value]);

  const valueMemo = useMemo(() => {
    if (displayValue) {
      if (!isSubLen) {
        // 避免后台不设置小数位数 导致布局烂掉
        let maxLen = len;
        if (len > 8) {
          maxLen = 6;
        } else if (len <= 0) {
          maxLen = 2;
        }
        return RenderUtil.FormatAmount(displayValue, maxLen, true);
      } else if (len || len === 0) {
        return RenderUtil.FormatAmount(displayValue, ratio ? 2 : len);
      } else {
        return RenderUtil.FormatAmount(displayValue);
      }
    }
    return <span className="color_secondary">0</span>;
  }, [displayValue, len]);

  if (!isSubLen)
    return (
      <span style={propsColor ? { ...combinedStyle, color: propsColor } : combinedStyle} className={`${className} !flex ${justify}`}>
        {unit}
        {valueMemo} {ratio ? '%' : ''}
        {suffix}
      </span>
    );

  return (
    <div
      style={
        propsColor ? { ...combinedStyle, color: propsColor } : combinedStyle
      }
      className={`!flex ${justify}  !items-center ${isMobile ? '' : 'font-[600]'}  ${className}`}
    >
      {isArrow && <span>{showShow}</span>}
      {unit}
      {valueMemo}
      {suffix}
      <span>{ratio ? '%' : ''}</span>
    </div>
  );
};

export default ValueDisplay;
