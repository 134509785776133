import type { SVGProps } from 'react';

export function BotSvg(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0.9 8.09961V12.5996C0.78181 12.5996 0.664778 12.5763 0.555585 12.5311C0.446392 12.4859 0.347177 12.4196 0.263604 12.336C0.180031 12.2524 0.113738 12.1532 0.0685084 12.044C0.0232792 11.9348 0 11.8178 0 11.6996L0 8.99961C0 8.76091 0.0948212 8.532 0.263604 8.36321C0.432387 8.19443 0.661305 8.09961 0.9 8.09961ZM17.1 8.09961C17.3387 8.09961 17.5676 8.19443 17.7364 8.36321C17.9052 8.532 18 8.76091 18 8.99961V11.6996C18 11.9383 17.9052 12.1672 17.7364 12.336C17.5676 12.5048 17.3387 12.5996 17.1 12.5996V8.09961Z"
        fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd"
            d="M1.5 7C1.5 6.44772 1.94772 6 2.5 6H15.5C16.0523 6 16.5 6.44772 16.5 7V15C16.5 15.5523 16.0523 16 15.5 16H2.5C1.94772 16 1.5 15.5523 1.5 15V7ZM4.5 10C4.5 9.44772 4.94772 9 5.5 9H6.5C7.05228 9 7.5 9.44772 7.5 10V11C7.5 11.5523 7.05228 12 6.5 12H5.5C4.94772 12 4.5 11.5523 4.5 11V10ZM11.5 9C10.9477 9 10.5 9.44772 10.5 10V11C10.5 11.5523 10.9477 12 11.5 12H12.5C13.0523 12 13.5 11.5523 13.5 11V10C13.5 9.44772 13.0523 9 12.5 9H11.5Z"
            fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd"
            d="M9.5 4.79198C10.383 4.4062 11 3.52516 11 2.5C11 1.11929 9.88071 0 8.5 0C7.11929 0 6 1.11929 6 2.5C6 3.52516 6.61705 4.4062 7.5 4.79198V6H9.5V4.79198Z"
            fill="currentColor" />
    </svg>
  );
}
