// 修改后的代码
import { cn } from '@/utils';
import { useUpdateEffect } from 'ahooks';
import { ChangeEvent, HTMLAttributes, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

// 去掉千分位
const parser = (value: string) => {
  if (!value) return value;
  return value?.replace(/\$\s?|(,*)/g, '');
};

export default function BaseInputNumber({
                                          value,
                                          step,
                                          title,
                                          onChange,
                                          max,
                                          min = 0,
                                          showButton = true,
                                          className,
                                          placeholder,
                                          onBlur,
                                          disabled = false,
                                          classNames = {
                                            inputBox: '',
                                            input: '',
                                          },
                                          len = 2,
                                          defaultValue = '0',
                                          onRealTimeInput,
                                        }: IProps) {
  // 增加千分位
  const formatter = (value: string) => {
    if (value === '') return '';
    if (!value) return value;
    // 分离整数部分和小数部分
    const [integerPart, decimalPart] = value.split('.');
    // 仅对整数部分添加千分位分隔符
    const formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ',',
    );
    // 重新组合整数部分和小数部分
    if (value.includes('.') && len !== 0) {
      return `${formattedIntegerPart}.${decimalPart}`;
    } else {
      return formattedIntegerPart;
    }
  };

  // 输入框绑定的值，添加对 value === 0 时使用 defaultValue 的逻辑
  const [inputValue, setInputValue] = useState(
    value === 0
      ? defaultValue
      : len !== 0
        ? formatter(Number(value).toFixed(len)?.toString()) || defaultValue
        : formatter(Number(value)?.toString()) || defaultValue,
  );
  const inputRef = useRef<HTMLInputElement>(null);

  const restoreCursorPosition = (position: number) => {
    if (inputRef.current) {
      inputRef.current.setSelectionRange(position, position);
    }
  };

  // 监听 value 的变化，添加对 value === 0 时使用 defaultValue 的逻辑
  useUpdateEffect(() => {
    if (value === 0 || value === null || value === undefined) {
      setInputValue(defaultValue);
      return;
    }
    let [integerPart, decimalPart] = value?.toString?.()?.split?.('.');
    let parsedValue: any = '0';
    if (decimalPart) {
      decimalPart = decimalPart?.slice(0, len);
      parsedValue = integerPart + '.' + decimalPart;
    } else {
      parsedValue = value;
    }
    setInputValue(formatter(parsedValue?.toString?.()));
  }, [value, len]);

  // 唯一个能更新值的函数--可以用于做一些校验
  const transformData = (data?: any, isAdd = false) => {
    if (data === 0 || data === '0' || data === '00') {
      onChange('0', isAdd);
      return;
    }

    if (
      ((data ?? inputValue) + '')?.trim() === '' ||
      (data ?? inputValue) === undefined
    ) {
      onChange('');
      return;
    }

    let defaultValue: string = (data ?? inputValue) + '';

    if (
      defaultValue.startsWith('0') &&
      !defaultValue.startsWith('0.') &&
      defaultValue.length > 1
    ) {
      defaultValue = defaultValue.replace(/^0+/, ''); // 只去掉非小数前的0
    }

    let dotCount = (defaultValue?.match?.(/\./g) || []).length;
    if (dotCount > 1) {
      const lastDotIndex = defaultValue.lastIndexOf('.');
      defaultValue =
        defaultValue.slice(0, lastDotIndex) +
        defaultValue.slice(lastDotIndex + 1);
    }

    let [integerPart, decimalPart] = defaultValue.split('.');
    let parsedValue: string = '0';

    if (decimalPart) {
      decimalPart = decimalPart?.slice(0, len);
      parsedValue = integerPart + '.' + decimalPart;
    } else {
      parsedValue = defaultValue;
    }

    onChange(parser(parsedValue), isAdd);
  };

  const handleClick = (type: 'plus' | 'minus') => {
    if (!showButton) return;

    const stepStr = step.toString();
    const decimalPlaces = stepStr.includes('.')
      ? stepStr.split('.')[1].length
      : 0;

    let newValue;
    let currentValue = parseFloat(parser(inputValue));
    if (Number.isNaN(currentValue) || !currentValue) {
      currentValue = 0;
    }
    if (type === 'plus') {
      newValue = parseFloat((currentValue + step).toFixed(decimalPlaces));
      if (max !== undefined && newValue > max) {
        return;
      }
    } else {
      newValue = parseFloat((currentValue - step).toFixed(decimalPlaces));
      if (min !== undefined && newValue < min) {
        transformData(min?.toString(), true);
        return;
      }
    }
    transformData(newValue, true);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    onRealTimeInput?.(e.target.value)
    transformData(e.target.value);
  };

  const handleInputBlur = () => {
    transformData();
    onBlur?.();
  };
  const { t } = useTranslation();

  return (
    <div
      className={`flex justify-between items-center bg-backgroundAuxiliaryColor rounded-md px-2  box-border h-[40px] ${className}`}
    >
      <div
        className={`text-auxiliaryTextColor text-xl cursor-pointer ${
          !showButton ? 'opacity-0' : ''
        }`}
        onClick={() => handleClick('minus')}
      >
        -
      </div>
      <div className="flex flex-col items-center flex-1">
        {title && (
          <span className="text-[10px] text-auxiliaryTextColor">{title}</span>
        )}
        <div className={`flex items-center relative ${classNames?.inputBox}`}>
          <input
            ref={inputRef}
            type="text"
            disabled={disabled}
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            placeholder={placeholder ?? t('请输入')}
            className={cn(
              'disableInputBoxHighlighting outline-0 text-[14px] bg-[transparent] w-full text-center text-titleColor hover:border-none hover:outline-none !placeholder:text-[#71717A]',
              classNames?.input ?? '',
            )}
          />
        </div>
      </div>
      <div
        className={`text-auxiliaryTextColor text-xl cursor-pointer ${
          !showButton ? 'opacity-0' : ''
        }`}
        onClick={() => handleClick('plus')}
      >
        +
      </div>
    </div>
  );
}

/**
 * 组件属性接口。
 */
interface IProps {
  title?: string;
  max?: number;
  min?: number;
  step: number;
  value: number;
  onChange: any;
  onRealTimeInput?: any;
  showButton?: boolean;
  className?: HTMLAttributes<HTMLDivElement>['className'];
  placeholder?: string;
  onBlur?: any;
  disabled?: boolean;
  classNames?: {
    inputBox?: HTMLAttributes<HTMLDivElement>['className'];
    input?: HTMLAttributes<HTMLDivElement>['className'];
  };
  len?: number;
  defaultValue?: string; //为 0的时候可以显示 其他的 ，比如控制符
}
