import type { SVGProps } from 'react';

export function LoansSvg(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.29028 1C6.23711 1 3.76318 3.35068 3.76318 6.25074C3.76318 9.15081 6.23711 11.5015 9.29028 11.5015C12.3435 11.5015 14.8174 9.15081 14.8174 6.25074C14.8174 3.35233 12.3412 1 9.29028 1ZM11.6658 6.44696L9.5998 8.41018C9.54356 8.46247 9.46961 8.49154 9.39281 8.49154C9.31601 8.49154 9.24206 8.46247 9.18582 8.41018L7.11979 6.44696C7.09291 6.42183 7.07149 6.39144 7.05684 6.35769C7.04219 6.32394 7.03463 6.28754 7.03463 6.25074C7.03463 6.21395 7.04219 6.17755 7.05684 6.1438C7.07149 6.11004 7.09291 6.07966 7.11979 6.05453L9.18582 4.09131C9.24206 4.03902 9.31601 4.00995 9.39281 4.00995C9.46961 4.00995 9.54356 4.03902 9.5998 4.09131L11.6658 6.05453C11.6927 6.07966 11.7141 6.11004 11.7288 6.1438C11.7434 6.17755 11.751 6.21395 11.751 6.25074C11.751 6.28754 11.7434 6.32394 11.7288 6.35769C11.7141 6.39144 11.6927 6.42183 11.6658 6.44696Z"
        fill="currentColor"
      />
      <path
        d="M3.91283 11.0378C4.08749 11.0013 4.26767 11.0223 4.42906 11.1002C5.39907 11.5745 8.38315 13.6692 9.33879 13.6692C10.4459 13.6692 14.7211 10.9482 15.2616 10.9482C15.8022 10.9482 16.4759 10.9996 16.4759 11.6353C16.4759 12.1714 11.3993 16.0862 9.88265 17.3536C9.74896 17.466 9.58739 17.5403 9.415 17.5685C9.24262 17.5967 9.06581 17.5778 8.90325 17.5139L3.85701 14.7216C3.57679 14.6111 3.26174 14.5558 3.00584 14.7177L2.32103 14.8758C2.29967 14.8889 2.27521 14.8961 2.25015 14.8967C2.22509 14.8973 2.20033 14.8912 2.17841 14.879C2.15648 14.8669 2.13817 14.8491 2.12536 14.8276C2.11254 14.806 2.10568 14.7815 2.10547 14.7564V12.1056C2.10503 11.9867 2.14539 11.8712 2.21983 11.7785C2.29427 11.6857 2.39828 11.6213 2.51448 11.596L3.91283 11.0378Z"
        fill="currentColor"
      />
    </svg>
  );
}
