import { MaterialSymbolsArrowBack } from '@/assets/icons/layout/MaterialSymbolsArrowBack';
import FeaturesCollect from '@/components/features/featuresCollect';
import { CollectEnum } from '@/enums/businessEnum';
import { ChartTypeEnum } from '@/models/kline';
import { history, useModel } from '@umijs/max';

/**
 *  图表顶部的导航
 */
export default () => {
  const { LocalChartInfo, coinType } = useModel('kline'); //选中币种的k line list

  return (
    <div className="flex justify-between px-4 pt-3 text-base items-center">
      <div
        className="flex items-center"
        onClick={() => {
          history.back();
        }}
      >
        <MaterialSymbolsArrowBack className="text-xl font-bold" />
        <div>
          <div className="ml-3 text-foreground text-base truncate">
            {LocalChartInfo?.alias}
          </div>
        </div>
      </div>
      <div>
        <FeaturesCollect
          collectType={
            coinType === ChartTypeEnum.COIN
              ? CollectEnum.COIN_COLLECT
              : CollectEnum.CONTRACT_COLLECT
          }
        />
      </div>
    </div>
  );
};
