import { LoadingSvg } from '@/assets/icons/comm/LoadingSvg';
import BaseConfirmModal from '@/components/base/baseConfirmModal';
import { BaseFlex } from '@/components/base/baseFlex';
import BaseModal from '@/components/base/baseModal';
import PageEnum from '@/enums/pageEnum';
import { createStockOrderApi } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import { history } from '@@/core/history';
import { Button, cn, useDisclosure } from '@nextui-org/react';
import { useModel } from '@umijs/max';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import TradeForm from './TradeForm';

//首页
export default (props: any) => {
  const { t } = useTranslation();
  const { reloadOrder } = props;

  const [isBuy, setIsBuy] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const vipModelRef = useDisclosure();
  const { refreshWallet } = useModel('user');

  //购买一个订单
  const { run: onSubmitRequest, loading: submitLoading } = useReq(
    createStockOrderApi,
    {
      loadingDefault: false,
      manual: true,
      onSuccess: () => {
        refreshWallet();
        onClose();
        reloadOrder();
        toast.success(t('操作成功'));
      },
      onError(e: any) {
        // 1006033003
        if (e?.resCode && e.resCode.toString().startsWith('1006033')) {
          onClose();
          vipModelRef.onOpen();
        }
      },
    },
  );

  //红涨绿跌设置
  const { currentDownBgClass, currentUpBgClass } = useModel('system');

  return (
    <div className="flex flex-shrink-0 sticky bg-background justify-between sm:relative bottom-16 py-2 sm:bottom-16 z-[1000] sm:z-[1] px-2 ">
      <Button
        onClick={() => {
          onOpen();
          setIsBuy(true);
        }}
        className={`${currentUpBgClass} w-full  text-white rounded-md`}
        spinner={<LoadingSvg />}
      >
        {t('做多')}
      </Button>

      <Button
        spinner={<LoadingSvg />}
        onClick={() => {
          // onOpen();
          // setIsBuy(false);
          history.replace(PageEnum.ORDER);
        }}
        className={cn(
          `${currentDownBgClass}  w-full  text-white rounded-md ml-4`,
          {
            'ml-4': true,
          },
        )}
      >
        {t('做空')}
      </Button>

      {/* 抽屉 */}
      <BaseModal
        isOpen={isOpen}
        hideCloseButton
        onClose={onClose}
        title={t('购买')}
      >
        <div>
          <TradeForm
            isBuy={isBuy}
            reloadOrder={reloadOrder}
            onSubmitRequest={onSubmitRequest}
            submitLoading={submitLoading}
            onClose={onClose}
          />
        </div>
      </BaseModal>

      <BaseConfirmModal
        isOpen={vipModelRef.isOpen}
        onClose={vipModelRef.onClose}
        okText={t('去订阅')}
        onOk={() => {
          vipModelRef.onClose();
          history.push(PageEnum.UPGRADEPLAN);
        }}
      >
        <BaseFlex align="center" justify="center" flex={1} vertical>
          <div>{t('提示')}</div>
          <div className="mt-1">
            {t('您还不是VIP无法完成购买，请升级VIP后重试!')}
          </div>
        </BaseFlex>
      </BaseConfirmModal>

      {/* <Modal
        className="mx-4 bg-background"
        isOpen={vipModelRef.isOpen}
        onOpenChange={vipModelRef.onOpenChange}
        placement="center"
      >
        <ModalContent className="text-backContrastColor">
          {(onClose: ((e: PressEvent) => void) | any) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                {' '}
                {t('提示')}
              </ModalHeader>
              <ModalBody>
                <p>{t('当前股票仅VIP可购买')}</p>
              </ModalBody>
              <ModalFooter>
                <Button
                  variant="light"
                  onPress={onClose}
                  className="cursor-pointer"
                >
                  {t('取消')}
                </Button>
                <Button
                  spinner={<LoadingSvg />}
                  isLoading={submitLoading}
                  className="mainColorButton !w-[80px] !py-[5px] cursor-pointer"
                  onClick={() => {
                    history.push(i.path);
                  }}
                >
                  {t('订阅VIP')}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal> */}
    </div>
  );
};
