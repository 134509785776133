import RangeSelector from '@/components/features/featuresRangeSelector';
import TradingViewChart from '@/components/features/featuresTradingViewChart';
import { useModel } from '@umijs/max';
import { useTheme } from 'next-themes';
import LoadingOverlay from './LoadingOverlay';

/**
 * 图表组件
 */
export default ({ len = 5, coinType = 0 }) => {
  //图表相关
  /**
   * setInterval 设置图表时间分辨率
   * setKlineData 设置图表数据
   * reloadChart 刷新
   */
  const {
    interval,
    resolutionsArr,
    setInterval,
    coinType: type,
    LocalChartInfo,
  } = useModel('kline');

  //主题
  const { isMobile } = useModel('system');

  //图表语言
  const { chartlanguage } = useModel('language');

  //使用主题
  const { theme } = useTheme();

  //币名
  const coinName = LocalChartInfo?.alias;

  //图表配置
  const chartProps = {
    interval: interval, //这个在变量里面 时间选择
    theme: theme, //dark |light
    symbol: LocalChartInfo?.symbol,
    len: len,
    locale: chartlanguage,
    title: LocalChartInfo?.alias,
  };

  return (
    <div style={{ paddingBottom: isMobile ? 100 : 50 }} className="h-full  flex flex-col">
      <div className="pb-1 px-4 flex-shrink-0">
        <RangeSelector
          value={interval}
          onChange={setInterval}
          options={resolutionsArr}
        />
      </div>

      <div className="flex-1 ">
        <LoadingOverlay key={interval + type + theme + coinName}>
          <TradingViewChart
            chartProperties={chartProps}
            className="px-2"
            len={len}
          />
        </LoadingOverlay>
      </div>
    </div>
  );
};
