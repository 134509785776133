import { cn } from '@/utils';
import React, { useEffect, useRef, useState } from 'react';

interface Option {
  text: string;
  value: string | number;
}

interface RangeSelectorProps {
  /** 当前选中的值（单选时为单个值，多选时为数组） */
  value: string | number | (string | number)[];
  /** 选项改变时的回调函数 */
  onChange: (value: string | number | (string | number)[]) => void;
  /** 可选项列表 */
  options: Option[];
  /** 是否多选模式 */
  multiple?: boolean;
}

const RangeSelector: React.FC<RangeSelectorProps> = ({
                                                       value,
                                                       onChange,
                                                       options,
                                                       multiple = false,
                                                     }) => {
  const [showScrollbar, setShowScrollbar] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (!showScrollbar) setShowScrollbar(true);
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      setShowScrollbar(false);
    }, 1000);

    return () => {
      clearTimeout(timerId);
    };
  }, [showScrollbar]);

  useEffect(() => {
    const container = containerRef.current;
    container?.addEventListener('scroll', handleScroll);

    return () => {
      container?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleSelect = (selectedValue: string | number) => {
    if (multiple) {
      const currentValues = Array.isArray(value) ? value : [];
      const newValues = currentValues.includes(selectedValue)
        ? currentValues.filter(v => v !== selectedValue)
        : [...currentValues, selectedValue];
      onChange(newValues);
    } else {
      onChange(value === selectedValue ? null : selectedValue);
    }
  };

  const isSelected = (optionValue: string | number) => {
    return multiple
      ? Array.isArray(value) && value.includes(optionValue)
      : value === optionValue;
  };

  return (
    <div
      ref={containerRef}
      className="text-xs w-full space-x-2"
      style={{
        display: 'flex',
        overflowX: 'auto',
        whiteSpace: 'nowrap',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        ...(showScrollbar && {
          scrollbarWidth: 'thin',
          msOverflowStyle: 'scrollbar',
        }),
      }}
      onScroll={handleScroll}
    >
      {options.map((option) => (
        <span
          key={option.value}
          onClick={() => handleSelect(option.value)}
          className={cn(
            "px-2 py-1 rounded-lg cursor-pointer transition-colors",
            {
              'bg-backgroundAuxiliaryColor': isSelected(option.value),
              'text-auxiliaryTextColor hover:bg-gray-100': !isSelected(option.value)
            }
          )}
        >
          {option.text}
        </span>
      ))}
    </div>
  );
};

export default RangeSelector;