import type { SVGProps } from 'react';

export function FundSvg(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      fill="none"
    >
      <path
        d="M11.9091 6C12.1984 6 12.4759 6.10536 12.6805 6.29289C12.8851 6.48043 13 6.73478 13 7V15C13 15.2652 12.8851 15.5196 12.6805 15.7071C12.4759 15.8946 12.1984 16 11.9091 16H2.09091C1.80158 16 1.52411 15.8946 1.31952 15.7071C1.11493 15.5196 1 15.2652 1 15V7C1 6.73478 1.11493 6.48043 1.31952 6.29289C1.52411 6.10536 1.80158 6 2.09091 6H11.9091ZM10.624 9.1175C10.5134 9.03204 10.3703 8.99036 10.2262 9.00161C10.082 9.01286 9.9487 9.07613 9.85545 9.1775L7.90818 11.295L5.90745 9.745C5.80296 9.66404 5.66909 9.62203 5.53239 9.62731C5.39569 9.63259 5.26615 9.68477 5.16945 9.7735L3.34164 11.4495C3.23938 11.5433 3.18193 11.6704 3.18193 11.803C3.18193 11.9356 3.23938 12.0627 3.34164 12.1565L3.39291 12.198C3.49786 12.2726 3.6289 12.3096 3.76146 12.3019C3.89402 12.2943 4.01899 12.2426 4.11291 12.1565L5.58618 10.8055L7.62182 12.382C7.6766 12.4244 7.73995 12.4565 7.80826 12.4764C7.87658 12.4963 7.9485 12.5037 8.01993 12.4981C8.09136 12.4926 8.16089 12.4741 8.22455 12.4439C8.2882 12.4137 8.34473 12.3723 8.39091 12.322L10.69 9.822C10.7832 9.72062 10.8287 9.58944 10.8164 9.45732C10.8042 9.32521 10.7351 9.20297 10.6245 9.1175H10.624Z"
        fill="currentColor"
      />
      <path
        d="M5 3H16V11.5"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
