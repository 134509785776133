import { BotSvg } from '@/assets/svg/trade/BotSvg';
import { FundSvg } from '@/assets/svg/trade/FundSvg';
import { LoansSvg } from '@/assets/svg/trade/LoansSvg';
import { FINANCIAL_MANAGEMENT_LIST_ENUM } from '@/enums/businessEnum';
import PageEnum from '@/enums/pageEnum';
import { ChartTypeEnum } from '@/models/kline';
import { Button } from '@nextui-org/react';
import { history, useModel } from '@umijs/max';
import { useTranslation } from 'react-i18next';

/**
 *  币币交易购买按钮
 */
export default function BatchButton() {
  const { t } = useTranslation();
  const { isMobile, currentDownBgClass, currentUpBgClass } = useModel('system');
  const { coinType } = useModel('kline');

  // @ts-ignore
  return (
    <div
      className={`flex-shrink-0 bg-background ${
        isMobile ? 'w-full fixed space-x-6  flex bottom-4 pl-4 z-20' : 'w-full'
      }`}
    >
      <div className="flex justify-between w-[100px] flex-shrink-0 text-[11px] font-medium">
        <div
          className="flex flex-col items-center  justify-center"
          onClick={() =>
            history.push(
              PageEnum.AITRADE +
                '?type=' +
                FINANCIAL_MANAGEMENT_LIST_ENUM.QUANTIFICATION_FINANCIAL_MANAGEMENT,
            )
          }
        >
          <BotSvg className="w-[16px] h-[16px]" />
          <div className={'pt-1 text-[11px]'}>{t('AI')}</div>
        </div>

        <div
          className="flex flex-col  items-center  justify-center"
          onClick={() =>
            history.push(
              PageEnum.AITRADE +
                '?type=' +
                FINANCIAL_MANAGEMENT_LIST_ENUM.REGULAR_FINANCIAL_MANAGEMENT,
            )
          }
        >
          <FundSvg className="w-[16px] h-[16px]" />
          <div className={'pt-1 text-[11px]'}>{t('理财')}</div>
        </div>

        <div
          className="flex flex-col justify-center items-center"
          onClick={() => history.push(PageEnum.LOANS)}
        >
          <LoansSvg className="w-[16px] h-[16px]" />
          <div className={'pt-1 text-[11px]'}>{t('贷款')}</div>
        </div>
      </div>

      <div className="flex flex-1 pr-1">
        <Button
          onClick={() => {
            if (coinType === ChartTypeEnum.CONTRACT) {
              history.replace(PageEnum.CONTRACT);
              return;
            }
            history.replace(PageEnum.TRANSACTION);
          }}
          className={`px-10 w-full mx-1 text-white ${currentUpBgClass} rounded-md`}
        >
          {t('买入')}
        </Button>
        <Button
          onClick={() => {
            if (coinType === ChartTypeEnum.CONTRACT) {
              history.replace(PageEnum.CONTRACT);
              return;
            }
            history.replace(PageEnum.TRANSACTION);
          }}
          className={`px-10 w-full mx-1 text-white ${currentDownBgClass} rounded-md`}
        >
          {t('卖出')}
        </Button>
      </div>
    </div>
  );
}
