import { SvgSpinnersBarsScale } from '@/assets/icons/layout/SvgSpinnersBarsScale';
import ThemeEnum from '@/enums/themeEnum';
import { useTheme } from 'next-themes';
import { useEffect, useState } from 'react';
import style from './index.less';

/**
 * 给图表适配的加载覆盖层
 */
const LoadingOverlay = ({ children, loading }: any) => {
  const [isLoading, setIsLoading] = useState(true);

  const { theme } = useTheme();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 800);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={style['container']}>
      {(isLoading || loading) && (
        <div
          className={
            theme === ThemeEnum.light
              ? style['lightOverlay']
              : style['darkOverlay']
          }
        >
          <SvgSpinnersBarsScale className=" text-primary text-2xl" />
        </div>
      )}
      {children}
    </div>
  );
};

export default LoadingOverlay;
