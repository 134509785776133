/**
 * 交易信息 TradeInfo
 */

import ValueDisplay from '@/components/display/displayValueDisplay';
import { getChartPageInfoApi } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import RenderUtil from '@/utils/RenderUtil';
import { useModel } from '@umijs/max';
import { useTranslation } from 'react-i18next';

type iProps = {
  [key: string]: any;
};

/**
 * @param props 符号 交易数据 24H
 * @returns
 */
export default function TradeInfo(props: iProps) {
  // 获取参数
  const {} = props || {};

  const { t } = useTranslation();
  const { getSocketRowByName } = useModel('socket');

  const { LocalChartInfo } = useModel('kline'); //选中币种的k line list
  const priceAccuracy = LocalChartInfo?.priceAccuracy ?? 6; //小数位数
  const socketData = getSocketRowByName(LocalChartInfo?.symbol);

  const { data = {} as any } = useReq(
    () =>
      getChartPageInfoApi({
        symbol: LocalChartInfo?.symbol,
        noMsg: true,
      }),
    {},
  );

  return (
    <div className="w-[100%] flex-shrink-0">
      <div className="pt-4 pb-3 flex justify-between px-4">
        <div>
          <div className=" text-2xl font-bold">
            {RenderUtil.FormatAmount(socketData?.a, priceAccuracy, true)}
          </div>
          <div className="mt-2">
            <span className="">
              <ValueDisplay
                value={socketData?.pe}
                ratio
                fontSize={12}
                justify="justify-start"
              />
            </span>
          </div>
        </div>

        <div className="flex text-xs">
          <div className="">
            <div>
              <div className=" text-auxiliaryTextColor">
                {t('24小时最高价')}
              </div>
              <div>
                {socketData?.a > data?.high
                  ? RenderUtil.FormatAmount(socketData?.a, priceAccuracy, true)
                  : RenderUtil.FormatAmount(data?.high, priceAccuracy, true)}
              </div>
            </div>
            <div className="pt-5">
              <div className=" text-auxiliaryTextColor">{t('24小时最低')}</div>
              <div>
                {socketData?.a < data?.low
                  ? RenderUtil.FormatAmount(socketData?.a, priceAccuracy, true)
                  : RenderUtil.FormatAmount(data?.low, priceAccuracy, true)}
              </div>
            </div>
          </div>

          <div className="ml-4">
            <div>
              <div className=" text-auxiliaryTextColor">
                {t('24小时成交额')}
              </div>
              <div>
                {RenderUtil.shortenNumber(RenderUtil.formatNum(data?.amount))}
              </div>
            </div>
            <div className="pt-5">
              <div className=" text-auxiliaryTextColor">
                {t('24小时交易量')}
              </div>
              <div>
                {RenderUtil.shortenNumber(RenderUtil.formatNum(data?.volume))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
