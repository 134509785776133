import BaseTabs from '@/components/base/baseTabs';
import { ChartTypeEnum } from '@/models/kline';
import TradeButton from '@/pages/stock/components/StockTradeButton';
import { useModel } from '@umijs/max';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import BatchButton from './components/BatchButton';
import ChartBox from './components/ChartBox';
import InfoBox from './components/ChartInfoBox';
import TopNav from './components/ChartTopNav';
import TradeInfo from './components/ChartTradeInfo';
import { cn } from '@/utils';

// 定义Tab选项枚举
enum TabOptions {
  TradeList = '1', // 交易信息列表
  Chart = '2', // 图表
}

/**
 * 图表页面
 */
export default () => {
  //交易按钮 TradeButtom Props
  const { interval } = useModel('kline'); //选中币种的k line list
  const { isMobile } = useModel('system');

  const { t } = useTranslation();

  const tabOptions = [{ text: t('报价'), value: '1' }];

  const [tabSelected, setTabSelected] = useState<TabOptions>(
    TabOptions.TradeList,
  );

  const { LocalChartInfo, coinType } = useModel('kline'); //选中币种的k line list
  const priceAccuracy = LocalChartInfo?.priceAccuracy ?? 6; //小数位数


  return (
    <div key={interval + ''} className={cn('flex flex-col',{
      'fixed top-0 left-0 right-0 bottom-0 flex flex-col':isMobile,
      'h-[80vh] ':!isMobile
    })}>
      {/* 顶部的导航 */}
      <div className="flex-shrink-0">
        <TopNav />
      </div>
      {/* 切换图表 或者信息界面 */}
      <div className="px-4">
        <BaseTabs
          value={tabSelected}
          onChange={setTabSelected}
          options={tabOptions}
        />
      </div>
      {/* // 图表页面 */}
      {tabSelected === TabOptions.TradeList && (
        <>
          {/* 交易信息 */}
          <TradeInfo />
          {/* 图表 */}
          <div className="flex-1">
            <ChartBox len={priceAccuracy ?? 2} />
          </div>
        </>
      )}
      {/* 交易信息 */}
      {tabSelected === TabOptions.Chart && (
        <>
          {/* 交易信息 */}
          <InfoBox />
        </>
      )}

      {/* 普通股票购买按钮 isIndex 1是指数，指数没有购买按钮*/}
      {coinType === ChartTypeEnum.STOCK ? (
        <>
          <TradeButton
            reloadOrder={() => {
              // setOrderKey(orderKey + 1);
            }}
          />
        </>
      ) : (
        <BatchButton></BatchButton>
      )}

      {/* 其他 的 购买按钮 */}
    </div>
  );
};
