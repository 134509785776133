import { getCoinDetailApi } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import { User } from '@nextui-org/react';
import { useModel } from '@umijs/max';
import { useTranslation } from 'react-i18next';

/**
 * 图表的详情
 */
export default () => {
  const { t } = useTranslation();

  const { LocalChartInfo, type } = useModel('kline');

  const { data = {} as any } = useReq(
    () =>
      getCoinDetailApi({
        symbol: LocalChartInfo?.symbol,
        type,
      }),
    {},
  );

  return (
    <div className="px-4 pt-4 flex-shrink-0">
      <div>
        <User
          name={data?.alias}
          description={t(
            '数据由 CoinMarketCap 提供，仅供参考，不作为任何形式的代表或者担保',
          )}
          avatarProps={{
            src: data?.icon,
          }}
          classNames={{
            wrapper: 'flex-1',
          }}
        />

        <div className="titleText pt-6">{t('介绍')}</div>
        <div
          className="pt-4 text-auxiliaryTextColor"
          dangerouslySetInnerHTML={{ __html: data?.detail }}
        ></div>
      </div>
    </div>
  );
};
