import BaseModal, { IProps } from '@/components/base/baseModal';
import { useTranslation } from 'react-i18next';

export default (
  props: IProps & {
    handleConfirm?: (data?: any) => any;
    onOk?: any;
    okText?: any;
    confirmModalClassName?: any;
    footer?: any;
  },
) => {
  const { t } = useTranslation();
  const {
    confirmModalClassName = { content: '' },
    okText = t('确认'),
    footer,
  } = props;
  return (
    <div>
      <BaseModal
        classNames={{
          title: 'text-[18px] pt-[8px]  font-semibold',
          modal:'sm:!w-[350px]'
        }}
        isShowCloseButton
        placement="center"
        {...props}
      >
        <div className="px-[24px] pb-[24px]">
          <div
            className={`text-auxiliaryTextColor text-[16px] text-center w-[263px] ${confirmModalClassName?.content}`}
          >
            {props.children}
          </div>
          {footer ?? (
            <div className="flex justify-center mt-[24px] gap-[12px]">
              <div
                className="flex sm:cursor-pointer justify-center items-center rounded-[8px] text-[16px] bg-backgroundAuxiliaryColor  w-[120px] h-[40px]"
                onClick={props?.onClose}
              >
                {t('取消')}
              </div>
              <div
                onClick={props?.onOk}
                className="flex  sm:cursor-pointer justify-center items-center rounded-[8px] text-[16px] bg-primary text-white w-[120px] h-[40px]"
              >
                {okText}
              </div>
            </div>
          )}
        </div>
      </BaseModal>
    </div>
  );
};
