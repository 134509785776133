import { AntDesignDownOutlined } from '@/assets/icons/comm/AntDesignDownOutlined';
import { LoadingSvg } from '@/assets/icons/comm/LoadingSvg';
import BaseInputNumber from '@/components/base/baseInputNumber';
import BaseTabs from '@/components/base/baseTabs';
import ValueDisplay from '@/components/display/displayValueDisplay';
import { MarkListType } from '@/enums/businessEnum';
import RenderUtil from '@/utils/RenderUtil';
import useUrlState from '@ahooksjs/use-url-state';
import {
  Accordion,
  AccordionItem,
  Button,
  Checkbox,
  Chip,
  Radio,
  RadioGroup,
  Select,
  SelectItem,
} from '@nextui-org/react';
import { useModel } from '@umijs/max';
import { useUpdateEffect } from 'ahooks';
import { useMemo, useState } from 'react';
// import { MarkListType } from '@/constants/MarkListType'; // Adjust the import path as necessary
import { useTranslation } from 'react-i18next';

const isDev = process.env.NODE_ENV === 'development';


/**
 * 股票交易 表单
 */
export default function TradeForm(props: any) {
  const { isBuy, submitLoading, onSubmitRequest } = props || {};

  const { t } = useTranslation();

  const { getSocketRowByName, marketList } = useModel('socket');
  const { LocalChartInfo } = useModel('kline');
  const soketData = getSocketRowByName(LocalChartInfo?.symbol);

  const [tabSelected, setTabSelected] = useState('1');

  const [mktPrice, setMktPrice] = useState(0.1);
  const [valueType, setValueType] = useState('1');

  const [buyType, seBuyType] = useState<any>(1);
  const [price, setPrice] = useState(0);
  const [num, setNum] = useState(1);
  const [tp, setTp] = useState<any>('');
  const [tl, setTl] = useState<any>('');

  const [selectedKeys, setSelectedKeys] = useState<any>(new Set([]));
  //是否打开了 止盈 止损
  const hasTlTp = selectedKeys.size !== 0;

  const [urlState] = useUrlState<any>({});
  const { type } = urlState || {};

  useUpdateEffect(() => {
    if (hasTlTp) {
      if (!tp && soketData?.a) {
        setTp(soketData?.a);
      }
      if (!tl && soketData?.a) {
        setTl(soketData?.a);
      }
    }
  }, [hasTlTp]);

  const onSubmit = () => {
    // 非UC股票购买
    onSubmitRequest({
      type: buyType[0], // 限价 市价
      limitPrice: price, // 限价的价格
      //交易符号主键 √
      symbolStockId: LocalChartInfo?.id,
      //数量 √
      num,
      //交易方向（1：做多，2：做空）
      direction: isBuy ? '1' : '2',
      //	保证金模式 （1：是全仓 2是逐仓） √
      // earnestMode: 1,
      //止损数量
      slNum: hasTlTp ? tl : undefined,
      //止损类型（1：百分比，2：固定金额，3：百分比+固定金额，4：固定百分比）暂时跳过
      slType: 4,
      //止盈数量
      spNum: hasTlTp ? tp : undefined,
      //止损类型（1：百分比，2：固定金额，3：百分比+固定金额，4：固定百分比）暂时跳过
      spType: 4,
      //来源（1：管理端，2：移动端，3：跟单） √
      source: 1,
      contractType: type === MarkListType.OPTIONS ? valueType : undefined,
    });
  };


  //是否休市
  // const isClose = soketData.st === 0;
  const isClose = !isDev &&  soketData.st === 0;

  //红涨绿跌设置
  const {
    currentUpClass,
    currentDownClass,
    //下面背景色
    currentDownBgClass,
    currentUpBgClass,
  } = useModel('system');

  // 一手
  const firstHand = LocalChartInfo?.firstHand;
  // 计算预估花费
  const estimatedCost = useMemo(() => {
    if (!num || !soketData?.a) {
      return 0;
    }
    return num * soketData.a;
  }, [num, soketData?.a, LocalChartInfo, firstHand]);

  const { language } = useModel('language');

  // 不知道要不要限价购买，
  const showLimitPrice = false;

  return (
    <div className="pt-3 pb-2 text-foreground px-4 h-full flex flex-col justify-between overflow-auto ">
      <div className="max-h-[80vh] overflow-y-auto">
        <div className="flex justify-between items-center">
          <BaseTabs
            value={tabSelected}
            onChange={setTabSelected}
            options={[{ text: isBuy ? t('市价') : t('市价'), value: '1' }]}
          />
        </div>

        {showLimitPrice && (
          <>
            <div className="pb-2 ">
              <div className="font-bold">{t('限/市价')}</div>
              <Select
                className="w-full mt-2 "
                placeholder={t('请选择限/市价')}
                style={{ height: 40 }}
                selectedKeys={[buyType] as any}
                onChange={(e: any) => {
                  if (e.target.value) {
                    seBuyType(e.target.value);
                  }
                }}
                classNames={{
                  trigger: `bg-backgroundAuxiliaryColor rounded-md h-[50px]`,
                }}
                popoverProps={{
                  classNames: {
                    content: 'bg-backgroundAuxiliaryColor text-foreground',
                  },
                }}
              >
                {[
                  { value: '1', label: t('市价') },
                  { value: '2', label: t('限价') },
                ].map((animal) => (
                  <SelectItem key={animal.value} value={animal.value}>
                    {animal.label}
                  </SelectItem>
                ))}
              </Select>
            </div>

            {+buyType[0] === 2 && (
              <div className="pb-2">
                <div className="font-bold">{t('价格')}</div>
                <BaseInputNumber
                  value={price}
                  onChange={(value: any) => {
                    setPrice(value);
                  }}
                  step={1}
                />
              </div>
            )}
          </>
        )}

        <div className="py-2 text-xs pt-2">
          <div className="pb-6 pt-1 flex justify-between">
            {/* <div className=" text-auxiliaryTextColor">{t('股票')}</div> */}
            <div>
              <div
                className={`${
                  isBuy ? currentUpClass : currentDownClass
                } text-base font-bold`}
              >
                {LocalChartInfo?.company}
              </div>

              <div className="text-iconFontColor text-xs truncate">
                {RenderUtil.removeSuffix(LocalChartInfo?.symbol)}
              </div>
            </div>
            <div>
              {LocalChartInfo?.vip === 1 && (
                <Chip variant="faded" color="success">
                  VIP
                </Chip>
              )}
            </div>
          </div>

          <div className="flex justify-between pb-3">
            <div className=" text-auxiliaryTextColor">{t('最新价格')}</div>
            <div className=" font-bold flex">
              <ValueDisplay value={soketData?.a} len={2} />{' '}
              <span className="text-[13px] ml-1">
                {LocalChartInfo?.coinAlias}
              </span>
            </div>
          </div>

          <div className="flex justify-between pb-3">
            <div className=" text-auxiliaryTextColor">{t('当日涨幅')}</div>
            <div className=" font-bold">
              <ValueDisplay value={soketData?.pe} len={2} ratio />
            </div>
          </div>

          {/* {firstHand !== 1 && (
            <div className="flex justify-between pb-3">
              <div className="text-auxiliaryTextColor">{t('换算')}</div>
              <div className="font-bold">
                1 {t('手')}= {firstHand}
                {t('股')}
              </div>
            </div>
          )} */}

          {Number(type) === Number(MarkListType.OPTIONS) && (
            <div className="pb-2">
              <RadioGroup
                value={valueType}
                onValueChange={(e) => {
                  setValueType(e);
                }}
              >
                <Radio value="0">{t('实值')}</Radio>
                <Radio value="1">{t('虚值')}</Radio>
              </RadioGroup>
            </div>
          )}

          {tabSelected === '2' && (
            <div className="pb-2 pt-2">
              <div className="font-bold">{t('价格')}</div>
              <BaseInputNumber
                value={mktPrice}
                step={1}
                max={9999999999}
                min={1}
                onChange={(value) => {
                  setMktPrice(value);
                }}
                className="py-6"
              />
            </div>
          )}

          <div className="pb-2">
            <div className="font-bold">{t('股')}</div>
            <BaseInputNumber
              value={num}
              step={1}
              max={9999999999}
              min={1}
              onChange={(value) => {
                setNum(value);
              }}
              className="py-6"
            />
          </div>

          <div className="flex pb-4">
            <div className=" text-auxiliaryTextColor">{t('预估花费')}</div>
            <div className="pl-1 font-bold">
              {RenderUtil.FormatAmount(estimatedCost)}{' '}
              {LocalChartInfo?.coinAlias}
            </div>
          </div>
          <Accordion
            selectedKeys={selectedKeys}
            onSelectionChange={setSelectedKeys}
          >
            <AccordionItem
              key="1"
              title={
                <span className="text-xs">
                  <Checkbox
                    isSelected={selectedKeys.size !== 0}
                    size="sm"
                    color="default"
                    onClick={() => {
                      setSelectedKeys(
                        selectedKeys.size === 0 ? new Set(['1']) : new Set([]),
                      );
                    }}
                  >
                    <span className="">{t('止盈/止损')}</span>
                  </Checkbox>
                </span>
              }
              indicator={<AntDesignDownOutlined />}
            >
              <div className="pb-2">
                <div className="font-bold">{t('止盈')}</div>
                <BaseInputNumber
                  value={tp}
                  step={0.01}
                  max={9999999999}
                  min={0.01}
                  onChange={(value) => {
                    if (!value && !tp && soketData?.a) {
                      setTp(soketData?.a);
                    } else {
                      setTp(value);
                    }
                  }}
                  className="py-6"
                />
                <div className="text-auxiliaryTextColor">
                  {language === 'en'
                    ? 'When the latest price is reached, it will trigger a market order to take profits.'
                    : t('当最新价格触达时，将会触发市价止盈')}
                </div>
              </div>

              <div className="pb-2">
                <div className="font-bold">{t('止损')}</div>
                <BaseInputNumber
                  value={tl}
                  step={0.01}
                  max={9999999999}
                  min={0.01}
                  className="py-6"
                  onChange={(value) => {
                    if (!value && !tl && soketData?.a) {
                      setTl(soketData?.a);
                    } else {
                      setTl(value);
                    }
                  }}
                />

                <div className="text-auxiliaryTextColor">
                  {language === 'en'
                    ? 'When the latest price is reached, it will trigger a market order to stop losses.'
                    : t('当最新价格触达时，将会触发市价止损')}
                </div>
              </div>
            </AccordionItem>
          </Accordion>
        </div>
      </div>

      {isClose ? (
        <Button disabled className="w-full my-2 rounded-md">
          <div className="text-xs">
            <div>{t('休市中')}</div>
          </div>
        </Button>
      ) : (
        <>
          {isBuy ? (
            <Button
              spinner={<LoadingSvg />}
              isLoading={submitLoading}
              onClick={() => {
                onSubmit();
              }}
              className={`${currentUpBgClass} w-full my-2 text-[#fff] rounded-md`}
            >
              <div className="text-xs">
                <div>{t('做多')}</div>
              </div>
            </Button>
          ) : (
            <Button
              spinner={<LoadingSvg />}
              isLoading={submitLoading}
              onClick={() => {
                onSubmit();
              }}
              className={`${currentDownBgClass} w-full my-2 text-[#fff] rounded-md`}
            >
              <div className="text-xs">
                <div>{t('做空')}</div>
              </div>
            </Button>
          )}
        </>
      )}
    </div>
  );
}
